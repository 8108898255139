<template>
    <div class="flex flex-col gap-6 justify-between max-h-full flex-1 overflow-y-auto overflow-x-hidden">
        <Card title="Assigned Client">
            <div v-if="loadingStates.fetchingDetails" class="flex flex-1 items-center justify-center py-14">
                <Loader />
            </div>

            <div v-else class="flex-1 w-full p-4 overflow-y-auto scroll-bar flex">
                <List :showTitle="false" :listItems="getClientsList" :showSelectAll="true" :showSearch="true" @handleSelect="$emit('selectAllClients', $event)" @handleFilterResult="handleFilterResult($event)">
                    <template #listheader>
                        <div class="px-4 py-1 grid text-gray-600 text-sm font-semibold" :class="`grid-cols-${headerList.length} ${headerList.length > 1 && 'tenant_element' }` " >
                            <span v-for="header in headerList" :key="header.name"> {{header.name}}</span>
                        </div>
                    </template>
                    <template #item="{ item }">
                        <TenantListItem :showLicenseAdd="showLicenseAdd" :tenant="item" @tenantAssign="$emit('tenantAssign', $event)" />
                    </template>
                </List>
            </div>
        </Card>
        <div class="flex items-center justify-end">
            <Button :disabled="disabledBUtton || loadingStates.submit" @click="$emit('handleFormSubmit', { data: getClientsList, section: 'license-client' })" type="primary" text="Save" />
        </div>
    </div>
</template>

<script>
import Button from "@shared/components/button";
import Card from "@shared/components/card";
import TenantListItem from "@shared/components/license-pack/tenant-list-item";
import Loader from "@shared/loader";
import List from "@shared/components/lists";

export default {
    name: "assign-tenent-card",
    components: {
        Button,
        Card,
        TenantListItem,
        Loader,
        List,
    },
    props: {
        licenseData: {
            type: Object,
            default: () => {},
        },
        loadingStates: {
            type: Object,
            default: () => {},
        },
        getClientsList: {
            type: Array,
            default: () => [],
        },
        showLicenseAdd:{
            type:Boolean,
            default: true
        },
        headerList:{
            type:Array,
            default: () => []
        },
        disabledBUtton: {
            type:Boolean,
        }
    },
    data() {
        return {};
    },
    computed: {
  isSaveButtonDisabled() {
    return !this.getClientsList.some((client) => client.checked);
  },
    },
    async mounted() {},
    methods: {
        handleFilterResult(event) {
            this.$emit("handleFilterResult", { data: event, type: "clients" });
        },
    },
};
</script>

<style lang="scss" scoped>

.tenant_element span:last-child{
    text-align: right;
}

</style>
